@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";

















































.home-page {
  background-color: $primary-color;
  .hero-teaser {
    position: relative;
    height: m(80);
    z-index: $z-index-base;
    .headline {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 10%;
      h1 {
        span {
          padding: 5px m(4) 5px m(8);
          @include font('jumbo');
          color: white();
          background-color: $primary-color; } }
      h2 {
        margin-top: m(2);
        span {
          padding: 3px m(3) 3px m(8);
          @include font('medium');
          color: white();
          background-color: $primary-color; } } } }
  .content {
    position: relative;
    margin-top: - m(20);
    z-index: $z-index-base + 1;
    .tile {
      height: 100%;
      padding: m(4);
      background-color: white();
      text-align: center;
      .svg-elem {
        width: 40%;
        fill: $primary-color; }
      h2 {
        margin-bottom: m(3);
        @include font('regular');
        color: $primary-color; } }
    .is-teaser {
      padding: m(3) 0 m(10) 0;
      * {
        color: white(); } } } }

@include md {
  .home-page {
    .hero-teaser {
      height: m(70);
      .headline {
        h1 {
          span {
            padding-left: content-padding('md');
            padding-right: m(2); } }
        h2 {
          span {
            padding-left: content-padding('md');
            padding-right: m(2); } } } } } }

@include sm {
  .home-page {
    .hero-teaser {
      height: m(70); } } }
